import React from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import { one } from 'images/product/salaris'
import Helmet from 'react-helmet'
import { belastingdienst , belastingdienstIllustration } from 'images'
import Layout from '../../../layouts'
import { confetti } from 'images/product/salaris'
import { wabPaper } from 'images'

class AanvragenLoonbelastingnummerPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="salaris tools animated fadeInPage">

          <Helmet>
            <title>Aanvraag gratis gids over de WAB gelukt | Employes</title>
            <meta name="description" content="Vanaf 1 januari 2020 treed de Wet arbeidsmarkt in balans (WAB) in werking. Hoe zorg je er nu voor dat jij als werkgever hierop goed bent voorbereid? In deze whitepaper leggen we uit wat er gaat veranderen en geven wij je bruikbare tips." />
            <meta itemprop="name" content="Download gratis gids over de WAB | Employes" />
            <meta itemprop="description" content="Vanaf 1 januari 2020 treed de Wet arbeidsmarkt in balans (WAB) in werking. Hoe zorg je er nu voor dat jij als werkgever hierop goed bent voorbereid? In deze whitepaper leggen we uit wat er gaat veranderen en geven wij je bruikbare tips." />
            <meta itemprop="image" content="/static/meta-WAB.png" />
            <meta name="robots" content="noindex,follow" />
         </Helmet>

          <header className="header padding-xxl animated fadeInPage">
            <div className="container-md">
              <div className="grid yg center text-center">

                <div className="col-12 margin-l-bottom">
                  <p className="eyebrow">WAB-gids</p>
                  <h1>De aanvraag is gelukt.</h1>
                  <p className="streamer center">We hebben de gids naar je emailadres gestuurd.</p>
                </div>

                <div className="col-12 no-pad relative whitepaper-img-container">
                  <div className="screenshot">
                    <img src={wabPaper} alt="Illustration" ref="screenshot" />
                  </div>

                  <div className="confetti">
                    <img src={confetti} alt="The confetti with different faces" />
                  </div>
                </div>
              </div>
            </div>
          </header>


        </main>
      </Layout>
    )
  }
}

export default AanvragenLoonbelastingnummerPage
